import React from 'react'

const Android = ({ className, color, ...rest }) => {
  return (
    <svg
      viewBox="0 0 22 27"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon ' + className}
      {...rest}
    >
      <g fillRule="evenodd">
        <path d="M4.85 8.1H4v12.5c0 .5.4.9.85.9h1.8v3.92c0 .87.65 1.58 1.48 1.58.82 0 1.5-.7 1.5-1.58V21.5h2.75v3.92c0 .87.67 1.58 1.5 1.58.82 0 1.48-.7 1.48-1.58V21.5h1.8c.45 0 .83-.4.83-.9V8.1H4.85zM20.33 8.1c-.92 0-1.66.7-1.66 1.56v7c0 .87.74 1.57 1.66 1.57.92 0 1.67-.7 1.67-1.56v-7c0-.87-.75-1.57-1.67-1.57M1.67 8.1C.75 8.1 0 8.8 0 9.66v7c0 .87.75 1.57 1.67 1.57.92 0 1.66-.7 1.66-1.56v-7c0-.87-.74-1.57-1.66-1.57M14.07 5.15c-.46 0-.84-.4-.84-.87 0-.48.38-.87.84-.87.47 0 .85.4.85.88s-.38.87-.85.87m-6.14 0c-.47 0-.85-.4-.85-.87 0-.48.38-.87.85-.87.46 0 .84.4.84.88s-.38.87-.84.87m6.47-3.2L15.6.2c.04-.03 0-.1-.07-.15-.07-.05-.14-.07-.17-.03L14.1 1.84c-.83-.34-1.85-.56-3.1-.56s-2.28.2-3.13.54L6.64.02c-.03-.04-.1-.02-.17.03s-.1.12-.08.15l1.18 1.74C4.1 3.54 4 7.42 4 7.42h14s-.14-3.85-3.6-5.46" />
      </g>
    </svg>
  )
}

export default Android
