import React, { useContext } from 'react'
import find from 'lodash/find'
import get from 'lodash/get'
import isString from 'lodash/isString'
import NextLink from 'next/link'

import { GlobalContext } from '@util/global_context'

const getEnglishPath = (paths, spanishPath) => {
  if (spanishPath === '/es') return '/'
  if (spanishPath.includes('sms')) return '/sms'

  const path = find(paths, path => {
    return get(path, 'fields.path["es"]') === spanishPath
  })
  return get(path, 'fields.path["en-US"]')
}

const getSpanishPath = (paths, englishPath) => {
  if (englishPath === '/') return '/es'
  if (englishPath.includes('sms')) return '/es/sms'

  let path = find(paths, path => {
    return get(path, 'fields.path["en-US"]') === englishPath
  })
  return '/es' + get(path, 'fields.path["es"]', '')
}

const parsePath = (path, href) => {
  const pathname = get(path, 'fields.path', '')
  const pathType = get(path, 'fields.type', '')
  const isSpecial = get(path, 'fields.path', '').match(/__.*__/)
  const isExternal = isExternalLink(href, pathType, pathname)
  return { pathname, pathType, isSpecial, isExternal }
}

const isExternalLink = (href, pathType, pathname) => {
  if (isString(href)) return true
  if (pathType === 'External Page') return true
  if (pathname.match(/^[http|https|mailto]$/)) return true
  return false
}

// This wrapper applies common styling
const Link = ({ path, href, icon, iconProps, children, className, ...rest }) => {
  const { slug, locale, internalPaths, setMailingListModalIsOpen } = useContext(GlobalContext)

  //Create the internal content of the link. This is the same regardless of link type
  const linkContent = (
    <React.Fragment>
      {icon && React.createElement(icon, { ...iconProps })}
      {children}
    </React.Fragment>
  )

  let { pathname, isSpecial, isExternal } = parsePath(path, href)

  const renderMailingListButton = () => (
    <button className={className} onClick={() => setMailingListModalIsOpen(true)} {...rest}>
      {linkContent}
    </button>
  )

  if (isSpecial) {
    let localePath = locale === 'es' ? '/es' : '/'
    switch (get(path, 'fields.path')) {
      case '__HOME__':
        pathname = localePath
        break
      case '__CHANGE_LANGUAGE__':
        pathname =
          locale === 'en-US'
            ? getSpanishPath(internalPaths, slug)
            : getEnglishPath(internalPaths, slug)
        break
      case '__OPEN_MAILING_LIST_MODAL__':
        return renderMailingListButton()
      default:
        break
    }
  } else if (!isExternal && locale === 'es' && path !== '/es' && path !== '/') {
    if (pathname.match(/^\//)) {
      pathname = pathname.slice(1)
    }
    pathname = `/es/${pathname}`
  }

  return (
    <NextLink
      href={href || pathname}
      className={className}
      data-link-type="external"
      data-link-label={href}
      {...rest}
    >
      {linkContent}
    </NextLink>
  )
}

export default Link
