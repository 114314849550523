import { useTranslation } from 'react-i18next'

// TODO: Replace with Next.js i18n support
export const useLocale = globalContext => {
  const { i18n } = useTranslation()

  const locale = (globalContext && globalContext.locale) ?? 'en-US'

  if (locale !== i18n.language) {
    i18n.changeLanguage(locale)
  }

  return locale
}
