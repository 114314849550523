import React from 'react'

const Apple = ({ className, color, ...rest }) => {
  return (
    <svg
      viewBox="0 0 21 26"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon ' + className}
      {...rest}
    >
      <g fillRule="evenodd">
        <path d="M16.07 0s.37 1.93-1.42 3.8c-1.92 1.98-4.1 1.65-4.1 1.65s-.4-1.56 1.2-3.4C13.55.02 16.07 0 16.07 0z" />
        <g transform="translate(0 6.158)">
          <path d="M10.66 1.2c.87 0 2.5-1.17 4.6-1.17 3.6 0 5.02 2.52 5.02 2.52s-2.78 1.4-2.78 4.77c0 3.8 3.47 5.12 3.47 5.12s-2.42 6.68-5.7 6.68c-1.5 0-2.67-1-4.25-1-1.6 0-3.2 1.04-4.26 1.04-3 0-6.76-6.34-6.76-11.43C0 2.73 3.2.1 6.2.1c1.94 0 3.45 1.1 4.46 1.1" />
        </g>
      </g>
    </svg>
  )
}

export default Apple
