import React from 'react'

const Twitter = ({ className, color, ...rest }) => {
  return (
    <svg
      viewBox="0 0 25 22"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon ' + className}
      {...rest}
    >
      <path
        d="M22.05 3.48C23.1 2.78 23.93 1.7 24.3.4c-.98.64-2.08 1.1-3.25 1.35C20.12.67 18.78 0 17.3 0 14.5 0 12.2 2.5 12.2 5.55c0 .44.04.86.13 1.27-4.25-.23-8.03-2.44-10.56-5.8-.44.82-.7 1.77-.7 2.8 0 1.9.9 3.6 2.3 4.6C2.48 8.4 1.7 8.16 1 7.75v.07c0 2.7 1.77 4.94 4.12 5.45-.43.13-.88.2-1.35.2-.33 0-.66-.04-.97-.1.65 2.2 2.55 3.8 4.8 3.85-1.76 1.5-3.97 2.38-6.38 2.38-.4 0-.82-.02-1.22-.07C2.27 21.1 4.97 22 7.86 22c9.44 0 14.6-8.46 14.6-15.8 0-.24 0-.48-.02-.72 1-.78 1.87-1.76 2.56-2.88-.92.45-1.9.74-2.95.88z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Twitter
