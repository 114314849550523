import React from 'react'

const Logo = ({ className, color, ...rest }) => {
  return (
    <svg
      viewBox="0 0 148 33"
      xmlns="http://www.w3.org/2000/svg"
      className={'logoIcon ' + className}
      {...rest}
    >
      <g fillRule="evenodd">
        <path d="M59 4.03s-7.18-.56-10.92 5.16V4L37 4.02V33h11.08v-8.8c0-5.45 3.53-11.75 10.92-12.94V4.03zM18 19.46L12 4H0l12.58 29H23.4L36 4H24" />
        <g transform="translate(54)">
          <path d="M28.5 22.87c-3.78 0-6.84-2.9-6.84-6.5 0-3.58 3.06-6.5 6.85-6.5 3.8 0 6.86 2.92 6.86 6.5 0 3.6-3.07 6.5-6.85 6.5m-19 3.8c-1.75 0-3.17-1.36-3.17-3.02s1.42-3 3.17-3c1.76 0 3.18 1.34 3.18 3s-1.42 3-3.18 3M28.5.08c-8.95 0-16.3 6.48-17.12 14.77-.6-.12-1.23-.18-1.88-.18-5.23 0-9.48 4.03-9.48 9 0 4.96 4.25 8.98 9.48 8.98 3.24 0 6.1-1.54 7.82-3.9 3 2.46 6.9 3.94 11.2 3.94 9.5 0 17.2-7.3 17.2-16.3S38 .08 28.5.08" />
        </g>
        <g transform="translate(102 4)">
          <path d="M37.6.02c-3.86 0-7.18 2.06-9.76 4.95-1.35-3.3-3.96-4.95-7.7-4.95-3.9 0-6.65 2.32-9.1 4.9V0L0 0v28h11.03V10.73c.92-.66 2.03-1.14 3.32-1.14 1.78 0 3.13.83 3.13 3.46V28H28.5V10.75c.94-.66 2.04-1.14 3.34-1.14 1.77 0 3.13.85 3.13 3.48V28H46V9.66C46 3.73 43.32.02 37.6.02" />
        </g>
      </g>
    </svg>
  )
}

export default Logo
