import React from 'react'
import classNames from 'classnames'

import styles from '@components/icons/menu.module.scss'

const Menu = ({ className, open, ...rest }) => {
  const openClass = open ? styles['menuIcon--open'] : null

  return (
    <svg
      viewBox="0 0 17 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles.menuIcon, openClass, className)}
      {...rest}
    >
      <g
        transform="translate(1)"
        stroke="#00B9B4"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <path
          className={classNames(styles.menuIconLine, styles['menuIconLine-1'])}
          d="M.5 1h14.04"
        />
        <path
          className={classNames(styles.menuIconLine, styles['menuIconLine-2'])}
          d="M.5 6h14.04"
        />
        <path
          className={classNames(styles.menuIconLine, styles['menuIconLine-3'])}
          d="M.5 11h14.04"
        />
      </g>
    </svg>
  )
}

export default Menu
