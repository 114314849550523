import React from 'react'
import classnames from 'classnames'

import Link from '@components/link'
import HeaderLogo from '@components/header_logo'
import MenuIcon from '@components/icons/menu'
import ScrollListener from '@util/scroll_listener'

const delta = 100
class Header extends React.Component {
  state = {
    visible: true,
    fixed: false,
    mobileMenuOpen: false,
    animate: false,
  }

  toggleMobileMenu = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    })
  }

  //Sets visibility state of header based on scrolling behavior
  onScroll = e => {
    const scrollY = e.scrollY
    const lastScrollY = this.lastScrollY || 0

    // Make sure they scroll more than delta before we take any action
    if (Math.abs(lastScrollY - scrollY) <= delta) return

    //If they are near the top of the page, nav should be static and always
    //visible. We also shouldn't be doing any animation to show/hide the header
    //in this case.
    if (scrollY < 300) {
      this.setState({
        fixed: false,
        visible: true,
        animate: false,
      })
    }

    //If they are significantly below the top of the page, then we can enable
    //animation of the show/hide effect for the header again.
    if (scrollY > 350) {
      this.setState({
        animate: true,
      })
    }

    // If they scrolled down significantly, then let's hide the navbar.
    if (scrollY > lastScrollY && scrollY > 100) {
      this.setState({
        visible: false,
        fixed: true,
      })
    } else {
      // If they scroll Up, let's hide the header.
      if (scrollY < lastScrollY) {
        this.setState({
          visible: true,
        })
      }
    }
    this.lastScrollY = scrollY
  }

  render() {
    const { currentPath, menuItems } = this.props

    const isCurrentPrefix = path => currentPath.fields.path.startsWith(path.fields.path)

    const isCurrentPath = path => currentPath.fields.path === path.fields.path

    const isCurrentSection = menuItemFields => {
      if (!currentPath) return false

      if (isCurrentPrefix(menuItemFields.path)) return true

      return !!(
        menuItemFields.subnavigation &&
        menuItemFields.subnavigation.fields.menuItems.find(menuItem =>
          isCurrentPath(menuItem.fields.path)
        )
      )
    }

    const items = menuItems.map(
      ({ fields }, i) =>
        fields && (
          <div
            key={i}
            className={classnames('header__menuItem', {
              'header__menuItem--selected': isCurrentSection(fields),
            })}
          >
            <Link path={fields.path} data-link-type="header" data-link-name={fields.name}>
              {fields.name}
            </Link>
          </div>
        )
    )

    const mobileMenu = (
      <div className="header__mobileMenu">
        <MenuIcon open={this.state.mobileMenuOpen} onClick={this.toggleMobileMenu} />
        <div
          className={classnames({
            headerMobileMenu__menu: true,
            'headerMobileMenu__menu--open': this.state.mobileMenuOpen,
          })}
          onClick={this.toggleMobileMenu}
        >
          {items}
        </div>
      </div>
    )

    const { animate, visible, fixed } = this.state
    return (
      <header
        className={classnames({
          header: true,
          hidden: !visible,
          static: !fixed,
          animate,
          visible,
          fixed,
        })}
      >
        <ScrollListener onScroll={this.onScroll} />
        <div className="header__content">
          <div className="header__logo">
            <Link path={{ fields: { path: '__HOME__' } }}>
              <HeaderLogo />
            </Link>
          </div>
          <nav className="header__menu">
            {mobileMenu}
            {items}
          </nav>
        </div>
      </header>
    )
  }
}

export default Header
