import React from 'react'
import throttle from 'lodash/throttle'
const ViewportContext = React.createContext('viewport')

export class ViewportProvider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      height: typeof window !== 'undefined' ? window.innerHeight : 1024,
      width: typeof window !== 'undefined' ? window.innerWidth : 1024,
    }
  }

  updateViewport = () => {
    if (this.state.height !== window.innerHeight || this.state.width !== window.innerWidth) {
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }
  }

  componentDidMount() {
    this.updateViewport()
    this.handleResize = throttle(this.updateViewport, 16)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    return (
      <ViewportContext.Provider value={this.state}>{this.props.children}</ViewportContext.Provider>
    )
  }
}

export const ViewportConsumer = ViewportContext.Consumer
export const withViewport = Component => {
  return class withViewport extends React.Component {
    render() {
      return (
        <ViewportConsumer>
          {viewport => <Component viewport={viewport} {...this.props} />}
        </ViewportConsumer>
      )
    }
  }
}
