import React from 'react'
import throttle from 'lodash/throttle'

class ScrollListener extends React.Component {
  componentDidMount() {
    const scrollElement = this.props.scrollElement || window

    this.handleScroll = throttle(() => {
      if (!this.props.onScroll) return
      this.props.onScroll({
        scrollY: scrollElement.pageYOffset,
        scrollX: scrollElement.pageXOffset,
        windowHeight: scrollElement.innerHeight,
        windowWidth: scrollElement.innerWidth,
      })
    }, 16)

    scrollElement.addEventListener('scroll', this.handleScroll)
    scrollElement.addEventListener('resize', this.handleScroll)
  }

  componentWillUnmount() {
    const scrollElement = this.props.scrollElement || window
    scrollElement.removeEventListener('scroll', this.handleScroll)
    scrollElement.removeEventListener('resize', this.handleScroll)
  }

  render() {
    if (!this.props.children) return null
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}

export default ScrollListener
