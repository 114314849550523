import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Logo from '@components/icons/logo'
import LogoTm from '@components/icons/logo_tm'
import LogoR from '@components/icons/logo_r'

const trademarkCountryCodes = ['CA', 'US']

const registeredCountryCodes = [
  'AE',
  'AT',
  'AU',
  'BE',
  'BG',
  'BR',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IL',
  'IN',
  'IT',
  'LB',
  'LT',
  'LU',
  'LV',
  'MT',
  'MX',
  'NL',
  'PL',
  'PT',
  'RO',
  'SA',
  'SE',
  'SG',
  'SI',
  'SK',
  'SV',
  'TZ',
]

const HeaderLogo = () => {
  const [countryCode, setCountryCode] = useState('US')

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const location = await axios.get(
          `https://geolocation-db.com/json/${process.env.NEXT_PUBLIC_GEOLOCATION_DB_API_KEY}`
        )

        setCountryCode(location.data.country_code)
      } catch {
        // Do nothing and use default logo
      }
    }

    fetchLocation()
  }, [])

  if (trademarkCountryCodes.includes(countryCode)) {
    return <LogoTm />
  }

  if (registeredCountryCodes.includes(countryCode)) {
    return <LogoR />
  }

  return <Logo />
}

export default HeaderLogo
