import React from 'react'
import Head from 'next/head'
import get from 'lodash/get'

const getPageTitle = (title, layout) => {
  if (layout === 'Homepage') return title

  return title + ' | Vroom.org'
}

const PageHead = ({ page, globalContext }) => {
  const pathname = globalContext.currentPathname

  const {
    featuredImage,
    hidePageFromSearchEngines,
    layout,
    metaDescription,
    socialMediaDescription,
    socialMediaTitle,
    title,
  } = page.fields

  React.useEffect(() => {
    const body = document.querySelector('body')
    body.setAttribute('data-location', pathname)
  }, [pathname])

  const pageTitle = getPageTitle(title, layout)

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="title" content={pageTitle} />
        <meta name="description" content={metaDescription} />
        {hidePageFromSearchEngines && <meta name="robots" content="noindex" />}

        {/* Open Graph / Facebook */}
        <meta property="og:url" content={'https://www.vroom.org' + pathname} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={socialMediaTitle ? socialMediaTitle : title} />
        <meta
          property="og:description"
          content={socialMediaDescription ? socialMediaDescription : metaDescription}
        />
        <meta
          property="og:image"
          content={
            featuredImage
              ? 'https:' + get(featuredImage, 'fields.file.url')
              : '/static/images/illustrations/hero-vroom.png'
          }
        />
      </Head>
    </>
  )
}

export default PageHead
