import React from 'react'

const LogoTm = ({ className, color, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 507.94 106.21"
      className={'logoIcon ' + className}
      {...rest}
    >
      <polygon points="57.34 62.36 38.29 12.3 0 12.3 40.11 106.17 74.62 106.17 114.75 12.3 76.45 12.3 57.34 62.36" />
      <path
        d="M194.25,35.73V12.31S170.92,10.52,158.77,29V12.22l-36,.08v93.87h36V77.64C158.77,60,170.25,39.59,194.25,35.73Z"
        transform="translate(0 0)"
      />
      <path
        d="M444.49,12.37c-12.64,0-23.47,6.9-31.93,16.6-4.41-11-12.94-16.6-25.18-16.6-12.66,0-21.69,7.79-29.72,16.41V12.33l-36.07,0v93.9h36.07V48.28a18,18,0,0,1,10.82-3.82c5.84,0,10.24,2.82,10.24,11.65v50.1h36V48.35a18.18,18.18,0,0,1,10.86-3.82c5.81,0,10.23,2.82,10.23,11.64v50h36.06V44.7C471.92,24.81,463.17,12.37,444.49,12.37Z"
        transform="translate(0 0)"
      />
      <path
        d="M258.75,0A53,53,0,0,0,206,48a29.86,29.86,0,0,0-5.8-.58,29.25,29.25,0,1,0,24.09,45.82A53,53,0,1,0,258.75,0ZM200.16,86.5a9.79,9.79,0,1,1,9.8-9.79A9.79,9.79,0,0,1,200.16,86.5Zm58.59-12.33a21.13,21.13,0,1,1,21.12-21.12A21.12,21.12,0,0,1,258.75,74.17Z"
        transform="translate(0 0)"
      />
      <path
        d="M506.85,93.29h-1a1.35,1.35,0,0,0-1.26.92l-2.62,6.5-2.63-6.5a1.29,1.29,0,0,0-1.25-.92h-1c-.72,0-1.08.4-1.08,1.21v10.67c0,.69.29,1,.87,1h.38c.55,0,.83-.35.83-1V96.33l2.75,6.67a1.32,1.32,0,0,0,.15.33.66.66,0,0,0,.29.17,2,2,0,0,0,.69.08c.64,0,1-.19,1.17-.58l2.7-6.71v8.88c0,.69.3,1,.88,1H507c.61,0,.92-.35.92-1V94.5C507.94,93.69,507.58,93.29,506.85,93.29Z"
        transform="translate(0 0)"
      />
      <path
        d="M492.77,93.42h-8.92c-.66,0-1,.3-1,.91v.17c0,.61.34.92,1,.92h3.38v9.75c0,.69.3,1,.91,1h.3a.91.91,0,0,0,1-1V95.42h3.33c.67,0,1-.31,1-.92v-.17C493.77,93.72,493.44,93.42,492.77,93.42Z"
        transform="translate(0 0)"
      />
    </svg>
  )
}

export default LogoTm
