import React from 'react'

const Youtube = ({ className, color, ...rest }) => {
  return (
    <svg
      viewBox="0 0 24 27"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon ' + className}
      {...rest}
    >
      <g fillRule="evenodd">
        <g transform="translate(.413 11.755)">
          <path d="M3.37 3.83V12h1.6V3.88H6.9V2.65H1.68v.94c-.02.2.05.24.24.24.47-.02.93 0 1.45 0zm17.8 4.87v-.1c0-.78 0-1.57-.04-2.36-.02-.43-.12-.85-.5-1.13-.73-.5-1.52-.57-2.33-.26-.83.33-1.17 1.03-1.2 1.86-.06 1-.06 2-.06 3 0 .96.34 1.77 1.3 2.2.78.33 1.5.22 2.17-.28.7-.52.87-1.2.66-2.05h-.56c-.2 0-.47-.06-.58.05-.1.1-.06.37-.05.57.03.56-.25.75-.78.76-.1 0-.2 0-.28-.02-.36-.05-.53-.23-.54-.6v-1.2-.44h2.78zM9.52 11.18V12h1.3V5.08h-1.6v5c0 .17-.04.36-.1.52-.1.2-.3.3-.53.3-.22-.02-.3-.22-.35-.4-.03-.07-.02-.15-.02-.23 0-1.65 0-3.3-.02-4.94V5.1H6.6V10c.02.45.06.9.1 1.32.02.3.2.5.47.64.32.15.66.12 1 .05.52-.1.97-.36 1.36-.82zm3.74.2c.3.2.55.37.84.5.7.36 1.4.16 1.68-.5.14-.33.27-.7.28-1.07.03-1.35.02-2.72 0-4.08 0-.42-.15-.8-.48-1.1-.45-.4-.96-.4-1.48-.15-.22.1-.42.22-.66.35V2.65h-1.62v9.16c0 .12.04.16.15.16l1-.02c.13 0 .22-.05.23-.2 0-.13.04-.24.07-.35zm10.3-8.32v8.47l-.03.1c-.23 1.23-1 2.05-2.1 2.54-.4.18-.87.25-1.3.38H3.46l-.2-.05c-1.23-.23-2.23-.8-2.84-1.94-.17-.3-.24-.66-.36-1v-8.5l.04-.13c.14-.72.48-1.32 1.03-1.8C1.96.37 2.93.04 4.03.04H19.6c.37 0 .75.04 1.1.13 1.13.28 2.04.87 2.55 1.94.15.3.22.63.33.94z" />
        </g>
        <path d="M9.9.06c-.3.75-.62 1.5-.93 2.24-.34.8-.68 1.57-1 2.36-.06.1-.07.22-.07.33v5.3H6.17v-.26-4.77c0-.34-.07-.64-.2-.94C5.32 2.95 4.7 1.56 4.07.18 4.04.14 4.04.1 4.03.06h1.84c.36 1.15.7 2.3 1.07 3.46.08-.1.12-.23.16-.34L8.07.06H9.9z" />
        <path d="M12.67 6.5V4.6c0-.33-.14-.58-.44-.7-.5-.24-1 .13-1 .73v3.7c0 .46.3.78.72.8.4 0 .72-.34.72-.8V6.5m1.46-.03c-.05.7-.07 1.47-.16 2.23-.1.88-.73 1.5-1.6 1.6-.55.1-1.1.05-1.62-.2-.16-.07-.3-.2-.43-.34-.34-.42-.5-.93-.5-1.46V4.72c.02-.27.08-.55.16-.82.2-.66.67-1.04 1.33-1.2.48-.1.94-.08 1.4.05.78.23 1.2.8 1.3 1.57.1.68.1 1.4.13 2.15" />
        <path d="M17.82 2.93h1.38v7.17h-1.75v-.54l-.17.2c-.3.43-.74.56-1.24.44-.47-.1-.74-.43-.82-.9-.06-.28-.1-.58-.1-.87V3.1v-.17h1.5v5.45c0 .4.18.62.5.66.4.05.7-.2.7-.6V4.77 2.93z" />
        <path d="M15.26 20.05v1.82c0 .57-.5.9-1.04.68-.3-.12-.4-.25-.4-.6v-3.77c0-.55.56-.88 1.06-.64.26.12.4.3.38.6v1.9" />
        <path d="M20.34 19.25h-1.5c0-.32-.02-.63 0-.94.04-.35.4-.57.84-.54.42.02.7.28.7.65 0 .28-.03.55-.04.85" />
      </g>
    </svg>
  )
}

export default Youtube
