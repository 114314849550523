import React from 'react'

const Pinterest = ({ className, color, ...rest }) => {
  return (
    <svg
      viewBox="0 0 25 22"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon ' + className}
      {...rest}
    >
      <g fillRule="evenodd">
        <path d="M19.45 9.34l-.16 1.28c-.34 2-1.05 3.8-2.4 5.3-1.4 1.53-3.1 2.3-5.18 2.26-.95-.02-1.8-.3-2.56-.83l-1-.68-.17-.08c-.1.4-.16.8-.25 1.2-.3 1.38-.63 2.76-1.2 4.06-.52 1.17-1.18 2.22-2.2 3.02l-.1.08-.08.04-.1-.7c-.17-1.77-.1-3.53.27-5.25.42-1.92.92-3.82 1.38-5.72l.33-1.4c0-.07 0-.16-.02-.23-.58-1.17-.6-2.4-.25-3.63.22-.82.6-1.55 1.28-2.1.87-.67 2.18-.57 2.93.23.33.37.4.83.4 1.3.02.66-.14 1.3-.32 1.92-.3 1.05-.62 2.1-.9 3.14-.13.46-.18.94-.13 1.42.1.8.56 1.34 1.33 1.55 1.54.43 2.77-.08 3.76-1.26.88-1.05 1.34-2.3 1.6-3.6.3-1.4.32-2.8 0-4.2-.46-2-1.74-3.24-3.64-3.86-1.93-.6-3.78-.28-5.53.67C5.4 3.9 4.44 4.8 3.75 5.94c-.77 1.27-1.08 2.64-.9 4.13.06.55.26 1.03.54 1.5.14.26.3.52.4.8.33.72.12 1.4-.25 2.05-.02.03-.1.05-.14.04-1.44-.34-2.47-1.17-3-2.58-.2-.57-.3-1.16-.36-1.77 0-.06-.03-.13-.04-.2V8.58l.1-.78c.25-1.62.9-3.05 2-4.28C3.72 1.64 5.77.52 8.23.16 8.6.1 8.94.08 9.3.04h1.32c.07 0 .13.03.2.03 1.64.12 3.2.5 4.65 1.33 2.18 1.26 3.5 3.12 3.87 5.64l.1.94v1.36z" />
      </g>
    </svg>
  )
}

export default Pinterest
