import React from 'react'
import classNames from 'classnames'

import styles from '@components/icons/email.module.scss'

const Email = ({ className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(styles.emailIcon, className)}
      {...rest}
    >
      <rect className="cls-1" x="1" y="3.7" width="18" height="12.6" rx="2" ry="2" />
      <polyline className="cls-1" points="19.11 6.11 10 11.11 0.89 6.11" />
    </svg>
  )
}

export default Email
