import React from 'react'
import { useTranslation } from 'react-i18next'

import AndroidIcon from '@components/icons/android'
import AppleIcon from '@components/icons/apple'
import FacebookIcon from '@components/icons/facebook'
import InstagramIcon from '@components/icons/instagram'
import Link from '@components/link'
import MailingListButton from '@components/mailing_list_button'
import PinterestIcon from '@components/icons/pinterest'
import TwitterIcon from '@components/icons/twitter'
import youtubeIcon from '@components/icons/youtube'
import { withGlobalContext } from '@util/global_context'

const Footer = props => {
  const { t } = useTranslation()

  const {
    menuItems,
    globalContext: { settings },
  } = props

  const createLink = (item, i) => (
    <div key={i} className="footer__menuItem">
      <Link path={item.fields.path} data-link-type="footer" data-link-name={item.fields.name}>
        {item.fields.name}
      </Link>
    </div>
  )

  const leftLinks = []
  const rightLinks = []
  menuItems.forEach((item, i) => {
    if (item.fields) {
      if (item.fields.style === 'Right') {
        rightLinks.push(createLink(item, i))
      } else {
        leftLinks.push(createLink(item, i))
      }
    }
  })

  const today = new Date()
  const currentYear = today.getFullYear()

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__grid">
          <div className="footer__col">{leftLinks}</div>
          <div className="footer__col">{rightLinks}</div>
          <div className="footer__col">
            <MailingListButton />
            <div className="footer__downloadLinks">
              {t('appDownload1')}
              <br />
              {t('appDownload2')}
              <div className="footer__downloadIcons">
                <Link
                  icon={AppleIcon}
                  href={settings.fields.appleAppStoreLink}
                  data-link-type="footer"
                />
                <Link
                  icon={AndroidIcon}
                  href={settings.fields.androidPlayStoreLink}
                  data-link-type="footer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__social">
        <Link
          target="_blank"
          rel="noopener"
          icon={TwitterIcon}
          href={settings.fields.twitterLink}
          data-link-type="social-footer"
          data-link-label="Twitter"
        />
        <Link
          target="_blank"
          rel="noopener"
          icon={FacebookIcon}
          href={settings.fields.facebookLink}
          data-link-type="social-footer"
          data-link-label="Facebook"
        />
        <Link
          target="_blank"
          rel="noopener"
          icon={InstagramIcon}
          href={settings.fields.instagramPageLink}
          data-link-type="social-footer"
          data-link-label="Instagram"
        />
        <Link
          target="_blank"
          rel="noopener"
          icon={PinterestIcon}
          href={settings.fields.pinterestPageLink}
          data-link-type="social-footer"
          data-link-label="Pinterest"
        />
        <Link
          target="_blank"
          rel="noopener"
          icon={youtubeIcon}
          href={settings.fields.youtubePageLink}
          data-link-type="social-footer"
          data-link-label="Youtube"
        />
      </div>
      <div className="copyright-text">
        {`Vroom 2018 - ${currentYear} © a program of the `}
        <a href="https://www.bezosfamilyfoundation.org">Bezos Family Foundation</a>
      </div>
    </footer>
  )
}

export default withGlobalContext(Footer)
