import React, { useContext } from 'react'
import Button from '@mui/material/Button'
import get from 'lodash/get'

import EmailIcon from '@components/icons/email'
import styles from '@components/mailing_list_button.module.scss'
import { GlobalContext } from '@util/global_context'

const MailingListButton = () => {
  const globalContext = useContext(GlobalContext)

  const newsletterTitle = get(globalContext, 'settings.fields.newsletterTitle')
  const { setMailingListModalIsOpen } = globalContext

  const onOpen = () => {
    setMailingListModalIsOpen(true)
  }

  return (
    <div className={styles.mailingListButton__container}>
      <div className={styles.mailingListButton} id="mc_embed_signup">
        <Button disableRipple classes={{ root: styles.mailingListButton__button }} onClick={onOpen}>
          <EmailIcon />
          <div className={styles.mailingListButton__title}>{newsletterTitle}</div>
        </Button>
      </div>
    </div>
  )
}

export default MailingListButton
