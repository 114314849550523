import React from 'react'

const Instagram = ({ className, ...rest }) => {
  return (
    <svg
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon ' + className}
      {...rest}
    >
      <g fillRule="evenodd">
        <path d="M23.65 13.015h-.04c0-1.09.03-2.182-.007-3.27-.037-1.106-.076-2.218-.226-3.312-.272-1.97-1.603-3.336-3.55-3.773-1.174-.264-2.37-.25-3.56-.267-2.19-.03-4.38-.033-6.57 0-1.105.018-2.22.06-3.31.23C4.554 2.91 3.292 3.95 2.73 5.765c-.34 1.103-.338 2.243-.36 3.376-.034 1.773-.05 3.546-.025 5.318.022 1.6.03 3.206.183 4.795.206 2.12 1.418 3.618 3.586 4.118 1.182.274 2.39.254 3.59.272 2.18.03 4.36.032 6.54-.002 1.106-.017 2.22-.058 3.31-.227 1.872-.29 3.165-1.35 3.68-3.213.17-.614.266-1.263.29-1.9.072-1.76.088-3.523.125-5.285M12.974.013c1.772.03 3.544.04 5.315.097 1.65.052 3.234.38 4.613 1.357 1.61 1.14 2.5 2.727 2.818 4.65.235 1.408.228 2.83.232 4.25.007 2.248.016 4.495-.02 6.74-.02 1.38-.064 2.764-.534 4.09-.884 2.495-2.65 3.997-5.218 4.486-1.154.22-2.35.273-3.53.29-2.587.04-5.176.03-7.763 0-1.38-.02-2.763-.066-4.09-.536-2.495-.883-3.95-2.66-4.505-5.215C.04 19.058.022 17.87.015 16.69c-.02-2.59-.023-5.177.01-7.765.016-1.378.063-2.762.533-4.09.884-2.493 2.66-3.95 5.215-4.504C6.937.08 8.123.077 9.306.054 10.53.03 11.752.047 12.976.047V.013" />
        <path d="M16.714 12.495c-.006-2.31-1.912-4.21-4.22-4.207-2.31.003-4.21 1.902-4.213 4.212-.002 2.32 1.915 4.23 4.233 4.22 2.31-.01 4.206-1.916 4.2-4.225M12.497 19C8.866 19 6.008 16.115 6 12.462 5.992 8.912 8.957 5.972 12.554 6c3.53.028 6.484 2.96 6.446 6.575C18.962 16.12 16.077 19 12.496 19M19.516 5c.85 0 1.487.648 1.484 1.505-.003.82-.673 1.494-1.485 1.495C18.693 8 18 7.31 18 6.492 18 5.665 18.678 5 19.516 5" />
      </g>
    </svg>
  )
}

export default Instagram
