import React from 'react'

const Facebook = ({ className, color, ...rest }) => {
  return (
    <svg
      viewBox="0 0 12 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon ' + className}
      {...rest}
    >
      <path
        d="M7.98 24H2.66V12H0V7.86h2.66V5.38C2.66 2 4.16 0 8.43 0h3.55v4.14H9.76C8.1 4.14 8 4.7 8 5.8l-.02 2.06H12L11.53 12H7.98v12z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Facebook
