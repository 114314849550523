import React from 'react'

const LogoR = ({ className, color, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 496.26 106.21"
      className={'logoIcon ' + className}
      {...rest}
    >
      <polygon points="57.34 62.36 38.29 12.3 0 12.3 40.11 106.17 74.62 106.17 114.75 12.3 76.45 12.3 57.34 62.36" />
      <path
        d="M194.25,35.73V12.31S170.92,10.52,158.77,29V12.22l-36,.08v93.87h36V77.64C158.77,60,170.25,39.59,194.25,35.73Z"
        transform="translate(0 0)"
      />
      <path
        d="M444.49,12.37c-12.64,0-23.47,6.9-31.93,16.6-4.41-11-12.94-16.6-25.18-16.6-12.66,0-21.69,7.79-29.72,16.41V12.33l-36.07,0v93.9h36.07V48.28a18,18,0,0,1,10.82-3.82c5.84,0,10.24,2.82,10.24,11.65v50.1h36V48.35a18.18,18.18,0,0,1,10.86-3.82c5.81,0,10.23,2.82,10.23,11.64v50h36.06V44.7C471.92,24.81,463.17,12.37,444.49,12.37Z"
        transform="translate(0 0)"
      />
      <path
        d="M258.75,0A53,53,0,0,0,206,48a29.86,29.86,0,0,0-5.8-.58,29.25,29.25,0,1,0,24.09,45.82A53,53,0,1,0,258.75,0ZM200.16,86.5a9.79,9.79,0,1,1,9.8-9.79A9.79,9.79,0,0,1,200.16,86.5Zm58.59-12.33a21.13,21.13,0,1,1,21.12-21.12A21.12,21.12,0,0,1,258.75,74.17Z"
        transform="translate(0 0)"
      />
      <path
        d="M489.92,106.09a6.12,6.12,0,0,1-4.56-1.8,6.81,6.81,0,0,1,0-9.18,6.68,6.68,0,0,1,9.12,0,6.21,6.21,0,0,1,1.76,4.58,6.28,6.28,0,0,1-1.78,4.6A6.13,6.13,0,0,1,489.92,106.09Zm0-1.12a4.89,4.89,0,0,0,3.69-1.48,5.74,5.74,0,0,0,0-7.58,5.35,5.35,0,0,0-7.39,0,5.75,5.75,0,0,0,0,7.58A4.89,4.89,0,0,0,489.94,105Zm2-1.88a1.2,1.2,0,0,1-1-.67l-1.16-1.65H489v1.46c0,.54-.26.82-.78.82H488c-.51,0-.76-.34-.76-1V97.33q0-1,.93-1h2c1.85,0,2.78.72,2.78,2.15a1.83,1.83,0,0,1-1.56,2l1.52,2s0,.11-.09.21a1.48,1.48,0,0,1-.4.29A1.1,1.1,0,0,1,491.9,103.09ZM489,97.73v1.73h1.12c.72,0,1.08-.29,1.08-.86s-.37-.87-1.12-.87Z"
        transform="translate(0 0)"
      />
    </svg>
  )
}

export default LogoR
