import React from 'react'

export const GlobalContext = React.createContext('globalContext')

export const GlobalContextProvider = GlobalContext.Provider
const GlobalContextConsumer = GlobalContext.Consumer
export const withGlobalContext = Component => {
  return class withGlobalContext extends React.Component {
    render() {
      return (
        <GlobalContextConsumer>
          {globalContext => <Component globalContext={globalContext} {...this.props} />}
        </GlobalContextConsumer>
      )
    }
  }
}
